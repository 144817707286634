<template>
    <section>
        <div class="page-header">
            <h3 class="page-title">
                Sections de la page "Accueil-Beekeeper"
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);">Accueil-Beekeeper</a></li>
                <li class="breadcrumb-item active" aria-current="page">Beekeeper</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <!-- Créer collection -->
                        <button v-b-modal.modalmd class="mb-4 text-right btn btn btn-gradient-primary btn-fw btn-secondary">Ajouter une collection de figcaption</button>
                        <b-modal id="modalmd" ref="addModal" title="Créer une nouvelle collection" hide-footer size="md">
                            <form class="forms-sample">
                                <!-- Entités  -->
                                <b-form-group label="Entités"  label-for="entity">
                                    <b-form-select id="entity" v-model="selectedEntitytypes" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                    <b-form-select-option class="select option" value=""></b-form-select-option>
                                    <b-form-select-option class="select option" :value="key.entitytype" v-for="(key,i) in entitytypes" :key="i">{{key.entitytype}}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>

                                 <!-- Nom  -->
                                <b-form-group label="Titre"  label-for="title">
                                    <b-form-select id="title" v-model="selectedTitle" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                    <b-form-select-option class="select option" value=""></b-form-select-option>
                                    <b-form-select-option class="select option" :value="essai" v-for="(essai,t) in entitytypesinfo" :key="t">{{essai}}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>

                                 <!-- description  -->
                                <b-form-group label="Description"  label-for="description">
                                    <b-form-select id="description" v-model="selectedDescription" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                    <b-form-select-option class="select option" value=""></b-form-select-option>
                                    <b-form-select-option class="select option" :value="essai" v-for="(essai,t) in entitytypesinfo" :key="t">{{essai}}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>

                                <div class="d-flex">
                                    <!-- <b-button type="" variant="success" class="mr-2" @click="submitCollection()">Submit</b-button> -->
                                <b-button variant="primary" @click="submitCollection()">Ajouter</b-button>
                                <b-button variant="dark" @click="closeModal()">Annuler</b-button>
                                </div>
                            </form>
                        </b-modal>

                        <!-- créer une collection -->
                        <button v-b-modal.create class="mb-4 text-right btn btn btn-gradient-primary btn-fw btn-secondary">Créer un figcaption</button>
                        <b-modal id="create" ref="addModal" title="Créer un nouveau figcaption" hide-footer size="md">
                            <form class="forms-sample">

                                 <!-- Nom  -->
                                <b-form-group label="Titre"  label-for="title">
                                    <b-form-input type="text" id="title" v-model="selectedTitle" placeholder="Titre du figcaption" size="lg" ></b-form-input>
                                </b-form-group>

                                 <!-- description  -->
                                <b-form-group label="Description"  label-for="description">
                                    <b-form-textarea type="text" :rows="3" :max-rows="6"></b-form-textarea>
                                </b-form-group>

                                 <b-form-group label="Ajouter une image" label-for="input8">
                                    <b-form-file v-model="file" id="image" :state="Boolean(file)" @change="onFileSelected" placeholder="Télécharger ou gliser une image..."></b-form-file>
                                </b-form-group>

                                <div class="d-flex">
                                    <!-- <b-button type="" variant="success" class="mr-2" @click="submitCollection()">Submit</b-button> -->
                                <b-button variant="primary" @click="submitElement()">Ajouter</b-button>
                                <b-button variant="dark" @click="closeElement()">Annuler</b-button>
                                </div>
                            </form>
                        </b-modal>

                        <div class="row portfolio-grid">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" v-for="(item,z) in figcaption" :key="z">
                                <figure class="effect-text-in">
                                <img :src="item.image" alt="image" />
                                <figcaption>
                                    <h4>{{item.title}}</h4>
                                    <p>{{item.description}}</p>
                                </figcaption>
                                </figure>
                            </div>
                        </div>

                        <!-- test -->
                        <!-- <form action="">
                            <input type="text" v-model="test1">
                            <input type="text" v-model="test2"> 
                            <button type="button" @click="push">pusher</button>
                        </form> -->

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const axios = require('axios').default
export default {
    name: 'sectionInfo',
    data () {
        return {
            selectedName:'',
            selectedEntitytypes: '',
            keys: [],
            categories:[],
            entitytypes: [],
            selectedEntitytypesInfo: [],
            entitytypesinfo: [],
            selectedTitle: '',
            selectedDescription: '',
            figcaption: [
                {title: 'essai', description: 'lorem ipsum be ity an', image:'https://picsum.photos/200'}
            ],
            submitEntitytype: [],
            tabtest: [
                {test1: 'akory1', test2: 'salama1'},
                {test1: 'akory2', test2: 'akory 2'},
                {test1: 'akory3', test2: 'akory 3'},
            ],
            essai: [
                {akory:'hehe'}
            ],
            file: null
        }
    },
    watch: {
         selectedEntitytypes (value) {
            // this.getEntitytypesInfo
            // console.log('keys2', this.selectedEntitytypesInfo)
            console.log('value',value);
             axios.get(`https://sites.comolapapaya.com/${this.selectedEntitytypes}`)
            .then ( resGetEntitypesInfo => {
                //éléments à submiter 
                this.submitEntitytype= resGetEntitypesInfo.data.result
                console.log('submit', this.submitEntitytype)

                this.entitytypesinfo= Object.keys(resGetEntitypesInfo.data.result[0])
                console.log('keys', this.entitytypesinfo)
                // console.log('entityTypesInfo', resGetEntitypesInfo)
            })
            .catch (errG => console.log('errorG',errG))
        },
        selectedEntitytypesInfo(value) {
            console.log('value entity',value);
        }
    },
    methods: {
        push() {
            for (let x of this.tabtest) {
                this.figcaption.push ( {title: x.test1, description: x.test2})
            }
        },
        submitCollection () {
            this.figcaption.pop()
            for ( let param of this.submitEntitytype) {
                this.figcaption.push ({
                    title: param[`${this.selectedTitle}`],
                    description: param[`${this.selectedDescription}`],
                    image: 'https://picsum.photos/200'
                    })
            }
        },
        closeModal () {
            this.selectedEntitytypes= ''
            this.selectedTitle=''
            this.selectedDescription=''
            this.$refs['addModal'].hide()
        },
        submitElement () {
            const imageFormData= new FormData
            imageFormData.append ('imgfile', this.file)
            this.figcaption.push ( {
                title: this.selectedTitle,
                description: this.selectedDescription,
                image: imageFormData
            } )
        },
        closeElement () {
            this.selectedEntitytypes= ''
            this.selectedTitle=''
            this.selectedDescription=''
            this.$refs['addModal'].hide()
        },
        onFileSelected (event) {
        console.log('event',event);
          this.file=event.target.files[0]
        }
    },
    mounted () {
        // console.log(Object.keys(this.test));
        // console.log(Object.keys(this.categories[0]));
        axios.get('https://sites.comolapapaya.com/entitytypes')
        .then (async resCategories=> {
            // this.keys = resCategories.data.result
            // console.log(resCategories)
            // console.log('keys',resCategories.data.result)
            // this.keys= Object.keys(resCategories.data.result[0])
            this.entitytypes= resCategories.data.result
            // console.log(this.keys);
        })
        .catch(errCategories => console.log(errCategories))
    }
}
</script>